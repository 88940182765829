import React from 'react'
import Pagebanner from '../Pagebanner'
import { Container, Row ,Col } from 'react-bootstrap'
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import Section1About from '../Section1About';
import Whyus from '../Whyus'

const pagebanner={
    title:"LIVE CHART",
    text:"Identify patterns and trends and respond to price action more effectively by typing in your chosen asset.",
    name:"LIVE CHART",
    pagelink:"livechart"
  }

function Livechart() {
  return (
    <div>
   <Pagebanner title={pagebanner.title} text={pagebanner.text} pagename={pagebanner.name}  pagelink={pagebanner.pagelink}/>
   <section>
            <Container>
            <Row className='titlerow2'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle  new-color">Live Chart</h2>
            </div>

        </Row>
            <Row>
                <div className="text-center">
                 <p>Identify patterns and trends and respond to price action more effectively by typing in your chosen asset. Apply moving averages, Bollinger Bands, and other technical indicators to enhance your trading</p>
                
                </div>
            </Row>
            </Container>
        </section>
        <section>
          <Container>
          <Row className='titlerow2'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle  new-color">Advanced Live Chart</h2>
            </div>

        </Row>
           
            <AdvancedRealTimeChart theme="light" width={'100%'}  height={500}></AdvancedRealTimeChart>
  
          </Container>
        </section>
        <section>
            <Whyus/>
        </section>

        
    </div>
  )
}

export default Livechart