import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import { Col, Row } from "react-bootstrap";

export default class Mianslider extends Component {



  render() {
    var settings = {


      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      speed: 2000,
     
      autoplaySpeed: 3000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  
    return (
      <div >
       
        <Slider {...settings}>
          <div className="slider">

         
        <Row>
          <Col lg={6} md={6} sm={12}>
          <div className="slidertext">
          <h2>THE ONLY PERSON IN CHARGE <br /> OF YOUR SUCCESS IS YOU
</h2>
                <p>Worldwide Reputable Multi-Asset Brokerage Firm The most advantageous trading conditions and the lowest spreads in the business are what we provide at {process.env.REACT_APP_APPNAME}</p>
                <div className=" sliederbtn ">
                    <div className="sliderbtn">

                <Link to={process.env.REACT_APP_REGISTER_LINK} target="_blank" className='commanbtn2'>Open Live Account</Link>
                    </div>
                    <div className="sliderbtn">

                <Link to={process.env.REACT_APP_DEMO_LINK} target="_blank" className='commanbtn2'>Open Demo Account</Link>
                    </div>
              
                  </div>
              </div>
          </Col>
          <Col lg={6} md={6} sm={12} className="imgcol1">
          <div className="sliderimg">
                <img src='/images/mt5-platform-hero2.webp' alt="" width={'100%'} />
              </div>
          </Col>
        </Row>
        </div>
          
          <div className="slider">

         
        <Row>
          <Col lg={6} md={6} sm={12}>
          <div className="slidertext">
          <h2>SLOW PROGRESS IS <br /> THAN NO PROGRESS</h2>
                <p>MetaTrader 5 (MT5) trading platform meet the growing demands of brokers and traders. MT5 Platform offers all the various features provided by the meta</p>
                <div className=" sliederbtn ">
                    <div className="sliderbtn">

                <Link to={process.env.REACT_APP_REGISTER_LINK} target="_blank" className='commanbtn2'>Open Live Account</Link>
                    </div>
                    <div className="sliderbtn" >

                <Link to={process.env.REACT_APP_DEMO_LINK} target="_blank" className='commanbtn2'>Open Demo Account</Link>
                    </div>
              
                  </div>
              </div>
          </Col>
          <Col lg={6} md={6} sm={12} className="imgcol1">
          <div className="sliderimg">
                <img src='./images/pngwing.com (11).png' alt="" width={'100%'} />
              </div>
          </Col>
        </Row>
        </div>
          
          <div className="slider">

         
        <Row>
          <Col lg={6} md={6} sm={12}>
          <div className="slidertext">
                <h2>Forex Trading made easy </h2>
                <p>Your search for trusted forex trading broker ends
                                    here. At {process.env.REACT_APP_APPNAME} we provide best profitable trading conditions and lowest
                                    spreads among the industry.</p>
                <div className=" sliederbtn ">
                    <div className="sliderbtn">

                <Link to={process.env.REACT_APP_REGISTER_LINK} target="_blank" className='commanbtn2'>Open Live Account</Link>
                    </div>
                    <div className="sliderbtn">

                <Link to={process.env.REACT_APP_DEMO_LINK} target="_blank" className='commanbtn2'>Open Demo Account</Link>
                    </div>
              
                  </div>
              </div>
          </Col>
          <Col lg={6} md={6} sm={12} className="imgcol1">
          <div className="sliderimg">
                <img src='/images/mac3.png' alt="" width={'100%'} />
              </div>
          </Col>
        </Row>
        </div>
          
          
          
          
        </Slider>
      </div>
    );
  }
}
