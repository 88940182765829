import React from 'react'
import { Container, Row } from 'react-bootstrap'
import AOS from 'aos';
import { useEffect } from 'react'
import { Link } from 'react-router-dom';
import 'aos/dist/aos.css';

const Pagebanner = (props) => {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <>
        <section className="pagebanner">
            <Container>
                <Row>
                    <div className="pagebannerouter" >
                    <div className="text-center "  >
                        <h1 className="pagetitle" data-aos="fade-up" style={{marginTop:"120px"}}>{props.title}</h1>
                        <p className="pagebannertext" >{props.text}</p>
                        <h4><Link to={'/'} style={{textDecoration:"none",color:"#fff"}}>Home</Link><span style={{color:"#fff" ,margin:"0 10px",fontSize:"25px"}}>   {">"} </span><Link to={`/${props.pagelink}`} style={{textDecoration:"none",color:"#fff"}}>{props.pagename}</Link></h4>
                    </div>
                    </div>
                </Row>
            </Container>


        </section>
    </>
  )
}

export default Pagebanner