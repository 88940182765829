import React from 'react'
import { Col, Container,Row } from 'react-bootstrap'
import AOS from 'aos';
import { useEffect } from 'react'
import 'aos/dist/aos.css';

const Whychose = () => {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <div>

        <section className='whychoosesection' >
            <Container>
            <Row className=''>
            <div className="text-center ">
                <h2 className="whychhose ">why choose us</h2>
            </div>
        </Row>
           
                <Row>
                    <Col lg={4} md={12} sm={12} >
                        <div className="col1">
                
                            <div className="col1box">
                                <h4>Spreads from 0 pips</h4>
                                <p>{process.env.REACT_APP_APPNAME}, you have an opportunity to trade with tight spreads from 0 pips.</p>

                            </div>
                            <div className="col1box">
                                <h4>Leverage up to 1:1000</h4>
                                <p>Trade with the leverage value up to 1:1000 regardless of the account type.</p>
                                
                            </div>
                            
                        </div>

                    </Col>
                    <Col lg={4} md={12} sm={12} >
                        <div className=" text-center">
    <img src="/images/ibkr-mobile-hero.png" alt="" width={'100%'} />
                        </div>

                    </Col>
                    <Col lg={4} md={12} sm={12} >
                        <div className="col1">
                        <div className="col1box2">
                            <h4>High execution speed</h4>
                            <p>Order execution from 0.1 second.</p>
                                
                        </div>
                        <div className="col1box2">
                            <h4>More than 60+ currency pairs</h4>
                            <p>Trade more than 60+ currency pairs, including EURUSD, USDJPY, GBPUSD, and EURGBP.</p>
                                
                    </div>

                        </div>

                    </Col>
                </Row>
            </Container>
        </section>
    </div>
  )
}

export default Whychose