import React from 'react'
import { Container, Row,Col } from 'react-bootstrap'
import AOS from 'aos';
import { useEffect } from 'react'
import 'aos/dist/aos.css';
const Section1About = () => {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <>
    <Container>
        
        <Row className='titlerow'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle  new-color">About {process.env.REACT_APP_APPNAME}</h2>
            </div>

        </Row>
        <Row className='aboutrowhome'>
            <Col lg={3} md={6} sm={12}data-aos="flip-left">
                <div className="box1">
                    <div className='insidebox'>
                        <h3>1:1000</h3>
                        <h6>Account Leverage</h6>
                    </div>
                </div>

            </Col>
            <Col lg={3} md={6} sm={12}>
            <div className="box1" data-aos="flip-left">
                    <div className='insidebox'>
                        <h3>3</h3>
                        <h6>Account Type</h6>
                    </div>
                </div>


            </Col>
            <Col lg={3} md={6} sm={12} data-aos="flip-left">
            <div className="box1">
                    <div className='insidebox'>
                        <h3>100+</h3>
                        <h6>Instruments</h6>
                    </div>
                </div>

            </Col>
            <Col lg={3} md={6} sm={12} data-aos="flip-left">
            <div className="box1">
                    <div className='insidebox'> 
                        <h3>0.0</h3>
                        <h6>Spreads As Low As 0 Pips</h6>
                    </div>
                </div>

            </Col>

        </Row>
    </Container>

    </>
  )
}

export default Section1About