import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Componets/Comman/Layout';
import Home from './Componets/Pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import Aboutus from './Componets/Pages/Aboutus';
import FAQ from './Componets/Pages/FAQ';
import ContanctUs from './Componets/Pages/ContanctUs';
import Forex from './Componets/Pages/Forex';
import Metals from './Componets/Pages/Metals';
import Stocks from './Componets/Pages/Stocks';
import Indices from './Componets/Pages/Indices';
import Mt5 from './Componets/Pages/Mt5';
import Accounts from './Componets/Pages/Accounts';
import EconomicCalendar from './Componets/Pages/Economic_Calendar';
import Livechart from './Componets/Pages/Livechart';
import Liveprice from './Componets/Pages/Liveprice';
import Education from './Componets/Pages/Education';
import IB from './Componets/Pages/IB';
import Pagenotfound from './Componets/Pages/Pagenotfound';
import Cryptocurrency from './Componets/Pages/Cryptocurrency';

function App() {
  return (
    <div>
    <BrowserRouter>
    <Routes>
      <Route element={<Layout/>}>
    <Route path="/" element={<Home/>} />
    <Route path="/aboutus" element={<Aboutus/>} />
    <Route path="/faq" element={<FAQ/>} />
    <Route path="/contactus" element={<ContanctUs/>} />
    <Route path="/forex" element={<Forex/>} />
    <Route path="/metals" element={<Metals/>} />
    {/* <Route path="/stocks" element={<Stocks/>} /> */}

    <Route path="/indices" element={<Indices/>} />
    <Route path="/Cryptocurrency" element={<Cryptocurrency/>} />
    <Route path="/mt5" element={<Mt5/>} />
    <Route path="/accounts" element={<Accounts/>} />
    <Route path="/EconomicCalendar" element={<EconomicCalendar/>} />
    <Route path="/livechart" element={<Livechart/>} />
    <Route path="/liveprice" element={<Liveprice/>} />
    <Route path="/Education" element={<Education/>} />
    <Route path="/partner" element={<IB/>} />
      </Route>
      <Route path='/*' element={<Pagenotfound/>}/>
    </Routes>
    </BrowserRouter>
    
    </div>
  );
}

export default App;
