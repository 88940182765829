import React from 'react'
import Pagebanner from '../Pagebanner'
import Section1About from '../Section1About'
import { Container, Row,Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Advantages from '../Advantages'
import StepProcess from '../StepProcess'
import Whyus from '../Whyus'
import AOS from 'aos';
import { useEffect } from 'react'
import 'aos/dist/aos.css';

const pagebanner={

    title:"INTRODUCING BROKERS",
    text:"Expand your business and build your client network while benefitting from our IB."
  }
const IB = () => {
    
  return (
    <div>
        <Pagebanner title={pagebanner.title} text={pagebanner.text}/>
        <section>
      <Container>
      <Row className='titlerow2'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle new-color">Benefits of IB</h2>
            </div>

        </Row>
    
        <Row className='Stepprocessrow'>
          <Col lg={3} md={12} sm={12} className='stepprocesscol' >
            <div className="step">
              <div className="iconstep">
                <img src="/images/icons8-payout-100.png" alt="" />
              </div>
              <div className="steptext">
                <h4>Daily Payout</h4>
                <p>Funds and withdrawals are accessible at any time</p>
              
              </div>
            </div>
          </Col>
          <Col lg={3} md={12} sm={12} className='stepprocesscol' >
            <div className="step">
              <div className="iconstep">
                <img src="/images/icons8-hand-shake-100.png" alt="" />
              </div>
              <div className="steptext">
                <h4>No Restrictions</h4>
                <p>Partnership program with no hidden restrictions or rules</p>
                
              </div>
            </div>
          </Col>
          <Col lg={3} md={12} sm={12} className='stepprocesscol' >
            <div className="step">
              <div className="iconstep">
                <img src="/images/icons8-headphone-100 (4).png" alt="" />
              </div>
              <div className="steptext">
                <h4>Excellent Customer Support</h4>
                <p>Quality assistance for 24/7</p>
   
              </div>
            </div>
          </Col>


        </Row>
        <Row className='Stepprocessrow'>
          <Col lg={3} md={12} sm={12} className='stepprocesscol' >
            <div className="step">
              <div className="iconstep">
                <img src="/images/icons8-commission-100.png" alt="" />
              </div>
              <div className="steptext">
                <h4>High Commision</h4>
                <p>Earn up to $14/lot</p>
              
              </div>
            </div>
          </Col>
          <Col lg={3} md={12} sm={12} className='stepprocesscol' >
            <div className="step">
              <div className="iconstep">
                <img src="/images/icons8-discount-100.png" alt="" />
              </div>
              <div className="steptext">
                <h4>Exclusive Promotions</h4>
                <p>Exclusive Promotions</p>
                
              </div>
            </div>
          </Col>
          <Col lg={3} md={12} sm={12} className='stepprocesscol' >
            <div className="step">
              <div className="iconstep">
                <img src="/images/icons8-gdpr-100.png" alt="" />
              </div>
              <div className="steptext">
                <h4>Transparency</h4>
                <p>Real time updates and daily reports on your trading will be provided</p>
   
              </div>
            </div>
          </Col>


        </Row>
        <Row>
          <div className="text-center" style={{margin:'50px 0'}}>
          <Link to={process.env.REACT_APP_REGISTER_LINK} className='commanbtn' target='_blank'> Open Live Account</Link>

          </div>
        </Row>
      </Container>
    </section>
        <section className='sectionib1'>
            <Container>
          
        <Row className='servicesrow'>
                <Col lg={6} md={6} sm={12} className='imgcolib'  >
                    <div className="seviceimg">
                        <img src='/images/pngwing.com (22).png' alt="" width={'100%'} />
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12}  >
                    <div className="sevicestext">
                        <div className='maintextsecvice' >
                            <h2 style={{color:"#ffff"}}>
We are not just The Best, <br />
We also Offer the Best</h2>
                            <p className='mypib'>{process.env.REACT_APP_APPNAME} offers an opportunity to all Introductory Brokers to gain more benefits than you can count.</p>
                            
                        </div>
                        <div className='ubullist'>
                            <ul style={{color:"#ffff"}}>
                                <li>Incredible Prize Offers!</li>
                                <li>Swap Free Accounts</li>
                            </ul>
                            <ul style={{color:"#ffff"}}>
                                <li>Expert Account Managers!</li>
                                <li>Guaranteed Unbeatable</li>
                            </ul>

            
                        </div>
                        <div className='ibbtn'>
                                <Link to={'/'} target='_blank'  className='dwnbtn'><i class="fa-brands fa-windows" style={{marginLeft:"0px !important"}}></i></Link>
                                <Link to={'/'} target='_blank'  className='dwnbtn'><i class="fa-brands fa-apple"></i></Link>
                                <Link to={'/'} target='_blank'  className='dwnbtn'><i class="fa-solid fa-mobile"></i></Link>
                                <Link to={'/'} target='_blank'  className='dwnbtn'><i class="fa-solid fa-globe"></i></Link>
                            </div>

                    </div>

                </Col>

            </Row>
            </Container>
        </section>
        <section>
            <Whyus/>
        </section>
  <section className='sectionib1'>
            <Container>
          
        <Row className='servicesrow'>
                <Col lg={6} md={6} sm={12} className='imgcolib2' >
                    <div className="seviceimg">
                        <img src='/images/mt5-platform-hero2.webp' alt="" width={'100%'} />
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12}  >
                    <div className="sevicestext">
                        <div className='maintextsecvice'>
                            <h2 style={{color:"#ffff"}}>
Who is the Introductory Broker <br />
Program designed for?</h2>
                            <p className='mypib'>Introductory Broker (IB) program works best with the partners with large trader community outreach

</p>
                            
                        </div>
                        <div className='ubullist'>
                            <ul style={{color:"#ffff"}} >
                                <li>Educators</li>
                                <li>Signal Providers</li>
                                <li>FinTech Firms</li>
                            </ul>
                            <ul style={{color:"#ffff"}}>
                                <li>Rebate Providers</li>
                                <li>EA Providers</li>
                                <li>Referrers</li>
                            </ul>

            
                        </div>
                        <div className='ibbtn'>
                                <Link to={'/'} target='_blank'  className='dwnbtn'><i class="fa-brands fa-windows" style={{marginLeft:"0px !important"}}></i></Link>
                                <Link to={'/'} target='_blank'  className='dwnbtn'><i class="fa-brands fa-apple"></i></Link>
                                <Link to={'/'} target='_blank'  className='dwnbtn'><i class="fa-solid fa-mobile"></i></Link>
                                <Link to={'/'} target='_blank'  className='dwnbtn'><i class="fa-solid fa-globe"></i></Link>
                            </div>

                    </div>

                </Col>

            </Row>
            </Container>
        </section>
        <section>
            <Container>
        
        <Row>
            <StepProcess/>
        </Row>
            </Container>
        </section>

    </div>
  )
}

export default IB