import React from 'react'
import { Link } from 'react-router-dom'
const Pagenotfound = () => {
  return (
    <div>
        <h1>404</h1>
        <h4>Page Not Found</h4>
        <Link to={'/'}>GO TO HOME</Link>
    </div>
  )
}

export default Pagenotfound