import React from 'react'
import Pagebanner from '../Pagebanner'
import Pricelist from '../Pricelist'
import { Container, Row ,Col} from 'react-bootstrap'
import StepProcess from '../StepProcess'
import Whychose from '../Whychose'
import TradeNow from '../TradeNow'
import Section1About from '../Section1About'
import Dwonloadnow from '../Dwonloadnow'
import Howtoopenacccountd from '../Howtoopenacccountd'
import Whyus from '../Whyus'

const pagebanner={
    title:"ACCOUNTS",
    text:"Experience the benefits of opening secure trading accounts with competitive offerings, zero commission, and the tightest spreads.",
    name:"ACCOUNTS ",
    pagelink:"accounts"
  }
const Accounts = () => {
  return (
    <div>
   <Pagebanner title={pagebanner.title} text={pagebanner.text} pagename={pagebanner.name}  pagelink={pagebanner.pagelink}/>
   
   <section>
    <Pricelist/>
   </section>
   <section>
        <Whychose/>
    </section>

 
    
 
   <section>
    <StepProcess/>
   </section>
   

    </div>
  )
}

export default Accounts