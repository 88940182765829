import React from 'react'
import { MarketData } from "react-ts-tradingview-widgets";
import Pagebanner from '../Pagebanner'
import { Container, Row ,Col} from 'react-bootstrap'
import TradeNow from '../TradeNow';
import Whychose from '../Whychose';
import StepProcess from '../StepProcess';
import OurProducts from '../OurProducts';
import Whyus from '../Whyus';
import { CryptoCurrencyMarket } from "react-ts-tradingview-widgets";

const pagebanner={
    title:"Cryptocurrency",
 
    name:"Cryptocurrency",
    pagelink:"Cryptocurrency"
  }

const Cryptocurrency  = () => {
  return (
    <div>
        <Pagebanner title={pagebanner.title} text={pagebanner.text}  pagename={pagebanner.name}  pagelink={pagebanner.pagelink}/>
        <section>
        <Container>
            <Row className='titlerow2'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle  new-color">WHAT IS Cryptocurrency </h2>
            </div>

        </Row>
            <Row>
                <div className="text-center">
                 <p>Cryptocurrencies also known as digital currencies are fast-growth investment instruments and attracting popularity worldwide. Traders can trade a wide range of crypto-currencies as the ideal asset in CFD trades. Available on MT5 now you can trade Bitcoin, Ether, Ripple, Lite coin, Dash, and Monaro against the USD dollar and the most innovative and popular digital currencies. Diversify your trading portfolio today with one of the industry’s safest providers of online trading. We offer cryptocurrencies on leverage with the advantage of short selling to deliver an ideal solution for hedging physical holding..</p>
                 <p>
                 Cryptocurrency is a digital payment system that doesn't rely on banks to verify transactions. It’s a peer-to-peer system that can enable anyone anywhere to send and receive payments. Instead of being physical money carried around and exchanged in the real world, cryptocurrency payments exist purely as digital entries to an online database describing specific transactions. When you transfer cryptocurrency funds, the transactions are recorded in a public ledger. Cryptocurrency is stored in digital wallet
                 </p>
                </div>
            </Row>
            </Container>
        </section>
        <section>
          <OurProducts/>
        </section>
        <section>
          <Container>
          <Row className='titlerow2'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle  new-color">MOST POPULAR  PAIRS</h2>
            </div>

        </Row>
            <Row>
            <CryptoCurrencyMarket colorTheme="light" width="100%" height={450}></CryptoCurrencyMarket>
            </Row>
          </Container>
        </section>
        
        <section>
  <TradeNow/>
    </section>
    <section>
          <Whyus/>
        </section>
    {/* <section>
        <Container>
        
        <Row>
            <StepProcess/>
        </Row>
        </Container>
    </section> */}
    </div>
  )
}

export default Cryptocurrency