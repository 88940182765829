import React from 'react'
import { MarketOverview } from "react-ts-tradingview-widgets";
import Pagebanner from '../Pagebanner'
import { Container, Row ,Col} from 'react-bootstrap'
import TradeNow from '../TradeNow';
import Whychose from '../Whychose';
import StepProcess from '../StepProcess';
import OurProducts from '../OurProducts';
import Whyus from '../Whyus';

const pagebanner={
    title:"Indices",
   
    name:"Indices",
    pagelink:"indices"
  }

const Indices = () => {
  return (
    <div>
         <Pagebanner title={pagebanner.title} text={pagebanner.text} pagename={pagebanner.name}  pagelink={pagebanner.pagelink}/>
        <section>
        <Container>
            <Row className='titlerow2'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle  new-color">WHAT IS indices</h2>
            </div>

        </Row>
            <Row>
                <div className="text-center">
                 <p>
In the context of trading, indices (plural of index) refer to measures or indicators that represent the performance of a group of assets, such as indices, bonds, or other financial instruments. These indices serve as benchmarks or gauges for the overall performance of a specific market or a segment of the market. Traders and investors use indices to track and assess the performance of a particular sector, industry, or the entire market.</p>
                 <p>
                 {process.env.REACT_APP_APPNAME} Group offers indices major, cross and exotic pairs to trade in the indices markets with deep liquidity. Benefit from the best trading tools and cutting-edge MetaTrader 5 trading platforms, available on all our accounts.
                 </p>
                </div>
            </Row>
            </Container>
        </section>
       
        <section>
          <OurProducts/>
        </section>
        <section>
          <Container>
          <Row className='titlerow2'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle  new-color">MOST POPULAR  PAIRS</h2>
            </div>

        </Row>
           
        <MarketOverview colorTheme="light" height={450} width="100%" showFloatingTooltip></MarketOverview>
  
          </Container>
        </section>
        <section>
            <Whyus/>
        </section>
        <section>
<TradeNow/>
    </section>
    <section>
        <Container>
       
        <Row>
            <StepProcess/>
        </Row>
        </Container>
    </section>

    </div>
  )
}

export default Indices