import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Route } from 'react-router'

const Whyus = () => {
  return (
    <div>
        <section>
            <Container>
            <Row className='titlerow2'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle  new-color">why {process.env.REACT_APP_APPNAME}</h2>
            </div>

        </Row>
                <Row>
                    <Col lg={4} md={12} sm={12}>
                        <div className="whyusbox1">
                            <div className="whyusiconbox">
                                <img src="/images/withdraw.png" width={80} alt="" />

                            </div>
                            <div className="whyusiconboxtext">
                                <h2 className='new-color'>Quick <br /> Withdrawal</h2>
                                <p>Quick Withdrawals</p>

                            </div>
                        </div>
                    
                    </Col>
                    <Col lg={4} md={12} sm={12}>
                        <div className="whyusbox1">
                            <div className="whyusiconbox">
                                <img src="/images/spread.png" width={80} alt="" />

                            </div>
                            <div className="whyusiconboxtext">
                                <h2 className='new-color'>Tight <br /> Spread</h2>
                                <p>Tight Spread</p>

                            </div>
                        </div>
                    
                    </Col>
                    <Col lg={4} md={12} sm={12}>
                        <div className="whyusbox1">
                            <div className="whyusiconbox">
                                <img src="/images/support.png" width={80} alt="" />

                            </div>
                            <div className="whyusiconboxtext">
                                <h2 className='new-color'>Customer  <br /> Support</h2>
                                <p>Customer Support</p>

                            </div>
                        </div>
                    
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={12} sm={12}>
                        <div className="whyusbox1">
                            <div className="whyusiconbox">
                                <img src="/images/aprroval.png" width={80} alt="" />

                            </div>
                            <div className="whyusiconboxtext">
                                <h2 className='new-color'> Account <br /> Approval</h2>
                                <p> Account Approval</p>

                            </div>
                        </div>
                    
                    </Col>
                    <Col lg={4} md={12} sm={12}>
                        <div className="whyusbox1">
                            <div className="whyusiconbox">
                                <img src="/images/deposit.png" width={80} alt="" />

                            </div>
                            <div className="whyusiconboxtext">
                                <h2 className='new-color'>Low  <br /> Deposit</h2>
                                <p>Low Deposit</p>

                            </div>
                        </div>
                    
                    </Col>
                    <Col lg={4} md={12} sm={12}>
                        <div className="whyusbox1">
                            <div className="whyusiconbox">
                                <img src="/images/secure.png" width={80} alt="" />

                            </div>
                            <div className="whyusiconboxtext">
                                <h2 className='new-color'>Safe  <br />& Secure</h2>
                                <p>Safe & Secure</p>

                            </div>
                        </div>
                    
                    </Col>
                </Row>
            </Container>
        </section>
    </div>
  )
}

export default Whyus