import React from 'react'
import Pagebanner from '../Pagebanner'
import { Container, Row ,Col } from 'react-bootstrap'
import { EconomicCalendar } from "react-ts-tradingview-widgets";
import TradeNow from '../TradeNow';
import StepProcess from '../StepProcess';

const pagebanner={
  title:"Economic Calendar",
  text:"Stay updated with our economic calendar. Keep track of upcoming economic events and their effects on market movements.",
  name:"Economic Calendar",
    pagelink:"EconomicCalendar"
  
}
const Economic_Calendar = () => {
  return (
    <div>
   <Pagebanner title={pagebanner.title} text={pagebanner.text} pagename={pagebanner.name}  pagelink={pagebanner.pagelink}/>
   <section>
            <Container>
            <Row className='titlerow2'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle  new-color">Economic Calendar</h2>
            </div>

        </Row>
            <Row>
                <div className="text-center">
                 <p>Stay updated with our economic calendar. Keep track of upcoming economic events and their effects on market movements An economic calendar is a schedule of events and releases of specific data affecting the rise and fall of financial assets. Keep up with all the latest company updates and overhauls. Be in the know and read up on all that happens here at XYZFX in our very own company news section. . Dive in, to our news section and find out all the latest news on the financial market as we post regular updates about the markets and their analysis.</p>
                
                </div>
            </Row>
            </Container>
        </section>
        <section>
          <Container>

        <EconomicCalendar colorTheme="light" height={450} width="100%"></EconomicCalendar>
          </Container>
        </section>
        <section>
          <TradeNow/>
        </section>
        <section>
        <Container>
      
        <Row>
            <StepProcess/>
        </Row>
        </Container>
    </section>
    </div>
  )
}

export default Economic_Calendar