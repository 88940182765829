import React, { useState } from 'react'
import Pagebanner from '../Pagebanner'
import Section1About from '../Section1About'
import { Container, Row ,Col } from 'react-bootstrap'
import AOS from 'aos';
import { useEffect } from 'react'
import 'aos/dist/aos.css';
import Whyus from '../Whyus';

const pagebanner={
    title:"EDUCATION",
    text:"Education is an essential component for successful trading. That’s why you can now explore a variety of valuable resources and information for all traders. Scroll down and find out more!",
    name:"EDUCATION",
    pagelink:"education"
  }
const Education = () => {
    useEffect(() => {
        AOS.init();
      }, [])
    const[box1,setbox1]=useState(true)
    const[box2,setbox2]=useState(false)
    const[box3,setbox3]=useState(false)
    const[box4,setbox4]=useState(false)
    const[click1,setclick1]=useState('aftertechnicaleducationbox')
    const[click2,setclick2]=useState('technicaleducationbox')
    const[click3,setclick3]=useState('technicaleducationbox')
    const[click4,setclick4]=useState('technicaleducationbox')
    function showbox1 (){
        setbox1(true);
        setbox2(false)
        setbox3(false)
        setbox4(false)
        setclick1('aftertechnicaleducationbox')
        setclick2('technicaleducationbox')
        setclick3('technicaleducationbox')
        setclick4('technicaleducationbox')

    }
    function hidebox1(){
        setbox1(false)
        
    }
    function showbox2 (){
        setbox2(true);
        setbox1(false)
        setbox3(false)
        setbox4(false)
        setclick2('aftertechnicaleducationbox')
        setclick1('technicaleducationbox')
        setclick3('technicaleducationbox')
        setclick4('technicaleducationbox')
    }
    function hidebox2(){
        setbox2(false)
    }
    function showbox3(){
        setbox2(false);
        setbox1(false)
        setbox3(true)
        setbox4(false)
        setclick3('aftertechnicaleducationbox')
        setclick1('technicaleducationbox')
        setclick2('technicaleducationbox')
        setclick4('technicaleducationbox')

    }
    function hidebox3(){
        setbox2(false)
    }
    function showbox4(){
        setbox2(false);
        setbox1(false)
        setbox3(false)
        setbox4(true)
        setclick4('aftertechnicaleducationbox')
        setclick1('technicaleducationbox')
        setclick3('technicaleducationbox')
        setclick2('technicaleducationbox')
    }
    function hidebox4(){
        setbox4(false)
    }
  return (
    <div>
   <Pagebanner title={pagebanner.title} text={pagebanner.text} pagename={pagebanner.name}  pagelink={pagebanner.pagelink}/>
   <section>
   <Whyus/>
   </section>
   <section className='whychoosesection' >
            <Container>
            <Row className=''>
            <div className="text-center ">
                <h2 className="whychhose ">Basics of Forex Trading</h2>
            </div>
        </Row>
           
                <Row>
                    <Col lg={4} md={12} sm={12}  >
                        <div className="col1">
                
                            <div className="col1box">
                                <h4>1. Introduction to Forex</h4>
                                <p>Definition of forex and its role in the global economy.Understanding currency pairs and exchange rates.</p>

                            </div>
                            <div className="col1box">
                                <h4>3. Key Terms</h4>
                                <p>Pips, lots, leverage, margin, bid/ask price, spread, and other essential terms.</p>
                                
                            </div>
                            
                        </div>

                    </Col>
                    <Col lg={4} md={12} sm={12}>
                        <div className=" text-center">
    <img src="/images/ibkr-mobile-hero.png" alt="" width={'100%'} />
                        </div>

                    </Col>
                    <Col lg={4} md={12} sm={12} >
                        <div className="col1">
                        <div className="col1box2">
                            <h4>
2. Currency Pairs</h4>
                            <p>Major, minor, and exotic pairs. Understanding base and quote currencies.</p>
                                
                        </div>
                        <div className="col1box2">
                            <h4>4. Market Structure</h4>
                            <p>Explanation of the interbank market and the role of brokers.</p>
                                
                    </div>

                        </div>

                    </Col>
                </Row>
                <Row>
                <div className="col5">
                    <div className="text-center">
                    <div className="col1box">
                                <h4>5. Market Participants</h4>
                                <p>Overview of major players: central banks, commercial banks, institutional investors, retail traders, and brokers.</p>
                                
                            </div>

                    </div>
                    </div>
                </Row>
            </Container>
        </section>
        <section>
            <Container>
            <Row className='titlerow'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle  new-color">Technical Analysis</h2>
            </div>

        </Row>
                <Row className='edutechnicalboxrow'>
                <Col lg={3} md={6} sm={12} className='educationtab' >
                    <div className={click1} onClick={showbox1} >
                        <div className="technicaleducationicon text-center">
                            <h4>1 <br />Candlestick  Patterns</h4>
                        </div>
                     
                    </div>
                </Col>
                <Col lg={3} md={6} sm={12} className='educationtab'>
                    <div className={click2} onClick={showbox2} >
                        <div className="technicaleducationicon">
                            <h4>2 <br /> Support & Resistance</h4>
                        </div>
                       
                    </div>
                </Col>
                <Col lg={3} md={6} sm={12} className='educationtab'  >
                    <div className={click3} onClick={showbox3}>
                        <div className="technicaleducationicon">
                            <h4>3 <br /> Indicators</h4>
                        </div>
                     
                    </div>
                </Col>
                <Col lg={3} md={6} sm={12} className='educationtab' >
                    <div className={click4} onClick={showbox4} >
                        <div className="technicaleducationicon">
                            <h4> 4 <br />Chart Patterns</h4>
                        </div>
                      
                    </div>
                </Col>
        
                
                </Row>
                <Row>
                    <Col> 
                    <div className="technicaltecxt">
                       {box1 && <div className="forbox1 text-center">
                        <p>Basics of candlestick analysis.Common patterns like doji, engulfing, and hammer.
                        Candlestick charts are a technical tool that packs data for multiple time frames into single price bars. This makes them more useful than traditional open, high, low, and close (OHLC) bars or simple lines that connect the dots of closing prices. Candlesticks build patterns that may predict price direction once completed. 

                        </p>
                           
                        </div>}
                      {box2 &&  <div className="forbox1 text-center">
                        <p>Identifying and drawing support and resistance levels.Trendlines and channels.Prices move because of supply and demand. When demand is greater than supply, prices rise. When supply is greater than demand, prices fall. Sometimes, prices will move sideways as both supply and demand are in equilibrium.

                        </p>
                           
                        </div>}
                        {box3 && <div className="forbox1 text-center">
                        <p>
                        Moving averages MACD,and others.How to use indicators to tradingTechnical indicators are used by traders to gain insight into the supply and demand of securities and market psychology. Together, these indicators form the basis of technical analysis. Metrics, such as trading volume, provide clues as to whether a price move will continue. In this way, indicators can be used to generate buy and sell signals.

                        </p>
                           
                        </div>}
                       {box4 && <div className="forbox1 text-center">
                        <p>
                        Head and shoulders, triangles, flags, and pennants.Recognizing and trading chart patterns.Since price patterns are identified using a series of lines or curves, it is helpful to understand trendlines and know how to draw them. Trendlines help technical analysts spot support and resistance areas on a price chart. Trendlines are straight lines drawn on a chart by connecting a series of descending peaks (highs) or ascending troughs (lows).
                        </p>
                           
                        </div>}

                    </div>
                    </Col>
                </Row>
            </Container>
        </section>

    </div>
    
  )
}

export default Education