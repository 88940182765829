import React from 'react'
import Pagebanner from '../Pagebanner'
import { Container, Row ,Col } from 'react-bootstrap'
import { ForexCrossRates } from "react-ts-tradingview-widgets";
import Pricelist from '../Pricelist';

const pagebanner={
    title:"LIVE PRICE",
    text:"You’ll find real-time rates on currencies, commodities, and indices..",
    name:"LIVE PRICE",
    pagelink:"liveprice"
  }
const Liveprice = () => {
  return (
    <div>
   <Pagebanner title={pagebanner.title} text={pagebanner.text} pagename={pagebanner.name}  pagelink={pagebanner.pagelink}/>
   <section>
            <Container>
            <Row className='titlerow2'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle new-color">Live Price</h2>
            </div>

        </Row>
            <Row>
                <div className="text-center">
                 <p>Identify patterns and trends and respond to price action more effectively by typing in your chosen asset. Apply moving averages, Bollinger Bands, and other technical indicators to enhance your trading</p>
                
                </div>
            </Row>
            </Container>
        </section>
        <section>
    <Container>
    <Row className='titlerow2'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle  new-color"></h2>
            </div>

        </Row>

        <Row>
    <ForexCrossRates colorTheme="light" width={'100%'}  height={450}></ForexCrossRates>

        </Row>
    </Container>

</section>
<section>
    <Pricelist/>
</section>

    </div>
  )
}

export default Liveprice