import React from "react";
import Pagebanner from "../Pagebanner";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import TradeNow from "../TradeNow";
import Services from "../Services";
import Dwonloadnow from "../Dwonloadnow";
import StepProcess from "../StepProcess";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
import OurProducts from "../OurProducts";
import Pricelist from "../Pricelist";
import Whyus from "../Whyus";

const pagebanner = {
  title: "MetaTrader 5",
  text: "MetaTrader 5 (MT5) trading platform meet the growing demands of brokers and traders. MT5 Platform offers all the various features provided by the meta",
  name: "METATRADER 5",
  pagelink: "mt5",
};
const OurMisson = {
  maintitle: ` `,
  img: "/images/what-is-metatrader5_1.svg",
  sevicetitle: "WHAT IS MT5?",
  text: "MT5 gives you access to our extensive line up of asset classes — including forex, stocks, indices, metals, — on a single trading platform. With complete access to advanced trade types and cutting-edge analytical tools, MultiBank Group pushes the MT5 experience to a new level, that’s whether you’re a new or experienced trader. The main benefit of the MT5 platform is that traders are not restricted to using the built-in analytical resources.",
};

const Mt5 = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <Pagebanner
        title={pagebanner.title}
        text={pagebanner.text}
        pagename={pagebanner.name}
        pagelink={pagebanner.pagelink}
      />

      <section>
        <Container>
          <Row className="titlerow2">
            <div className="text-center centerrow">
              <h2 className="sectiontitle  new-color">
                Trade with {process.env.REACT_APP_APPNAME}
              </h2>
            </div>
          </Row>
          <Row className="mt5row">
            <Col md={6} lg={6} sm={12}>
              <div className="mt5image">
                <img
                  src="/images/pngaaa.com-6514522.png"
                  alt="MT5 Image"
                  width={"100%"}
                />
              </div>
            </Col>
            <Col md={6} lg={6} sm={12}>
              <div className="mt5text">
                <div className="sevicestext">
                  <div className="maintextsecvice">
                    <h2>DOWNLOAD THE MT5 PLATFORM</h2>
                    <img
                      src="/images/gtbfx-logo.png"
                      width={300}
                      alt=""
                      className="mt5logoimg"
                    />
                    <p className="myp">
                      Select a download type as per your device operating system
                      and your account regulation:
                    </p>
                  </div>
                  <div className="mt5btn">
                    <Link to={"/"} target="_blank" className="dwnbtn">
                      <i
                        class="fa-brands fa-windows"
                        style={{ marginLeft: "0px !important" }}
                      ></i>
                    </Link>
                    <Link to={"/"} target="_blank" className="dwnbtn">
                      <i class="fa-brands fa-apple"></i>
                    </Link>
                    <Link to={"/"} target="_blank" className="dwnbtn">
                      <i class="fa-solid fa-mobile"></i>
                    </Link>
                    <Link to={"/"} target="_blank" className="dwnbtn">
                      <i class="fa-solid fa-globe"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <TradeNow />
      </section>
      <section>
        <Whyus />
      </section>
      <section>
        <Dwonloadnow />
      </section>

      <section>
        <Pricelist />
      </section>

      {/* <section>
        <Container>
        <Row>
            <StepProcess/>
        </Row>
        </Container>
    </section> */}
    </div>
  );
};

export default Mt5;
