import React from 'react'
import Pagebanner from '../Pagebanner'
import { Container, Row } from 'react-bootstrap'
import StepProcess from '../StepProcess'
import { ForexCrossRates } from "react-ts-tradingview-widgets";
import TradeNow from '../TradeNow';
import Whychose from '../Whychose';
import OurProducts from '../OurProducts';

const pagebanner={
    title:"FOREX",
    
    name:"Forex",
    pagelink:"forex"
  }
const Forex = () => {
  return (
    <div>
        <Pagebanner title={pagebanner.title} text={pagebanner.text}  pagename={pagebanner.name}  pagelink={pagebanner.pagelink}/>
        <section>
            <Container>
            <Row className='titlerow2'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle  new-color">WHAT IS FOREX?</h2>
            </div>

        </Row>
            <Row>
                <div className="text-center">
                 <p>Forex is the largest financial market in the world with a daily turnover of approximately $6.6 Trillion. It operates through a global network of banks, companies and individuals who constantly buy and sell currencies from each other. The Foreign Exchange market has no physical location and operates 24 hours a day. Refer to our Trading Hours and be informed on the relevant dates and trading sessions.</p>
                 <p>
                 {process.env.REACT_APP_APPNAME} Group offers Forex major, cross and exotic pairs to trade in the Forex markets with deep liquidity. Benefit from the best trading tools and cutting-edge MetaTrader 5 trading platforms, available on all our accounts.
                 </p>
                </div>
            </Row>
            </Container>
        </section>
        <section>
            <OurProducts/>
        </section>
        <section>
    <Container>
    <Row className='titlerow2'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle  new-color">MOST POPULAR FX PAIRS</h2>
            </div>

        </Row>

        <Row>
    <ForexCrossRates colorTheme="light" width={'100%'}  height={450}></ForexCrossRates>

        </Row>
    </Container>

</section>
<section>
    <Whychose/>
    </section>
    <section>
        <Container>
        
        <Row>
            <StepProcess/>
        </Row>
        </Container>
    </section>
    

   

    </div>
  )
}

export default Forex