import React from "react";
import Pagebanner from "../Pagebanner";
import Section1About from "../Section1About";
import Services from "../Services";
import { Container, Row, Col } from "react-bootstrap";
import TradeNow from "../TradeNow";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
import Whyus from "../Whyus";
import Dwonloadnow from "../Dwonloadnow";

const AboutCompany = {
  maintitle: `ABOUT ${process.env.REACT_APP_APPNAME}`,
  img: "/images/pngegg (16) copy.png",
  sevicetitle: "YOU HAVE ALL YOUR BASES COVERED WITH US.",
  text: "Here at HPK Trade , we provide one of the safest online trading platforms to our clients and partners. We believe in developing a sustainable workforce through our years-long experience, in-depth knowledge of the financial market, and the association of our trusted partners.",
};
const OurMisson = {
  maintitle: ` `,
  img: "/images/about.png",
  sevicetitle: "THE RIGHT WAY OF TRADING",
  text: "Welcome to HPK Trade, the world's leading trading platform designed for traders of all levels. For people interested in FOREX, CFD, or commodities markets, we strive to provide programs, services, and information that will make them more profitable, self-sufficient, and efficient",
};
const pagebanner = {
  title: "About Us",
  text: "Empowering Traders Worldwide About HPK Trade",
  name: "Aboutus",
  pagelink: "aboutus",
};
const Aboutus = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <Pagebanner
        title={pagebanner.title}
        text={pagebanner.text}
        pagename={pagebanner.name}
        pagelink={pagebanner.pagelink}
      />

      <section style={{ margin: "100px 0 " }}>
        <Services
          maintitle={AboutCompany.maintitle}
          img={AboutCompany.img}
          sevicetitle={AboutCompany.sevicetitle}
          text={AboutCompany.text}
        />
      </section>
      <section>
        <Dwonloadnow />
      </section>

      {/* <section>
         <Whyus/>
        </section> */}

      <section>
        <Services
          maintitle={OurMisson.maintitle}
          img={OurMisson.img}
          sevicetitle={OurMisson.sevicetitle}
          text={OurMisson.text}
        />
      </section>
    </div>
  );
};

export default Aboutus;
