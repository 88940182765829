import React from 'react'
import {Row, Col, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import { useEffect } from 'react'
import 'aos/dist/aos.css';

const Pricelist = () => {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <div>
         <section>
        <Container>
        <Row className='titlerow2'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle  new-color">{process.env.REACT_APP_APPNAME} ACCOUNTS</h2>
            </div>

        </Row>
        <Row className='pricelistrow'>
            <Col lg={4} md={6} sm={12} className='pricelistcol'  data-aos="zoom-in-up">
                <div className="pricelist">
                    <div className="headeing">
                        <h4>STANDARD</h4>
                        <hr />
                        <h2>$100</h2>
              
                
                    </div>
                    <div className="listbox">
                        <ul>
                            <li>Initial Deposit : $100</li>
                            <li>Leverage : Up to 1:500</li>
                            <li>Commission : Zero</li>
                            <li>Spread : Fixed from 1.5 pips</li>
                            <li>Account Currency : USD</li>
                            <li>Trading Platform : MT5</li>
                        </ul>
                    </div>
                </div>
            </Col>
            <Col lg={4} md={6} sm={12}  className='pricelistcol'  data-aos="zoom-in-up">
                <div className="pricelist">
                    <div className="headeing">
                        <h4>ECN</h4>
                        <hr />
                        <h2>$500</h2>
                    
                    </div>
                    <div className="listbox">
                        <ul>
                            <li>Initial Deposit : $100</li>
                            <li>Leverage : Up to 1:500</li>
                            <li>Commission : Zero</li>
                            <li>Spread : Fixed from 1.5 pips</li>
                            <li>Account Currency : USD</li>
                            <li>Trading Platform : MT5</li>
                        </ul>
                    </div>
                </div>
            </Col>
            <Col lg={4} md={6} sm={12}  className='pricelistcol'  data-aos="zoom-in-up">
                <div className="pricelist">
                    <div className="headeing">
                        <h4>PRO</h4>
                        <hr />
                        <h2>$1000</h2>
          

                    </div>
                    <div className="listbox">
                        <ul>
                            <li>Initial Deposit : $100</li>
                            <li>Leverage : Up to 1:500</li>
                            <li>Commission : Zero</li>
                            <li>Spread : Fixed from 1.5 pips</li>
                            <li>Account Currency : USD</li>
                            <li>Trading Platform : MT5</li>
                        </ul>
                    </div>
                </div>
            </Col>
        </Row>
        <Row>
            <div className="text-center" style={{margin:'50px 0'}}>
            <Link to={process.env.REACT_APP_REGISTER_LINK} className='commanbtn2' target='_blank'> Open Live Account</Link>

            </div>
        </Row>
        </Container>
    </section>
    </div>
  )
}

export default Pricelist