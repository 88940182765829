import React from "react";
import "../../Css/Style.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { debounce } from "lodash";
const Header = () => {
  const [menu1, setmenu1] = useState(false);
  const [menu2, setmenu2] = useState(false);
  const [menu3, setmenu3] = useState(false);
  const [menu4, setmenu4] = useState(false);
  const [menu5, setmenu5] = useState(false);
  const [menu6, setmenu6] = useState(false);
  const [mobileview, setmobileview] = useState(false);
  const [scrollToTop, setScrollToTop] = useState(false);

  const handleScrollToTop = debounce(() => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setScrollToTop(false);
  }, 200); // Adjust the debounce time as needed

  useEffect(() => {
    if (scrollToTop) {
      handleScrollToTop();
    }
  }, [scrollToTop]);
  function showmenu1() {
    setmenu1(true);
    setmenu2(false);
    setmenu3(false);
    setmenu4(false);
    setmenu5(false);
    setmenu6(false);
  }
  function hidemenu1() {
    setmenu1(false);
  }
  function showmenu2() {
    setmenu2(true);
    setmenu1(false);
    setmenu3(false);
    setmenu4(false);
    setmenu5(false);
    setmenu6(false);
  }
  function hidemenu2() {
    setmenu2(false);
  }
  function showmenu3() {
    setmenu3(true);
    setmenu1(false);
    setmenu2(false);
    setmenu4(false);
    setmenu5(false);
    setmenu6(false);
  }
  function hidemenu3() {
    setmenu3(false);
  }
  function showmenu4() {
    setmenu3(false);
    setmenu1(false);
    setmenu2(false);
    setmenu5(false);
    setmenu6(false);
    setmenu4(true);
  }
  function hidemenu4() {
    setmenu4(false);
  }
  function showmenu5() {
    setmenu3(false);
    setmenu1(false);
    setmenu2(false);
    setmenu4(false);
    setmenu6(false);
    setmenu5(true);
  }
  function hidemenu5() {
    setmenu5(false);
  }
  function showmenu6() {
    setmenu3(false);
    setmenu1(false);
    setmenu2(false);
    setmenu4(false);
    setmenu6(true);
    setmenu5(false);
  }
  function hidemenu6() {
    setmenu6(false);
  }
  function show1() {
    setmenu1(!menu1);
  }
  function show2() {
    setmenu2(!menu2);
  }
  function show3() {
    setmenu3(!menu3);
  }
  function show4() {
    setmenu4(!menu4);
  }
  function show5() {
    setmenu5(!menu5);
  }
  function show6() {
    setmenu6(!menu6);
  }

  return (
    <div>
      <nav className="mainnav">
        <div className="logorowformobile">
          <div className="bar" onClick={() => setmobileview(true)}>
            <i class="fa-solid fa-bars"></i>
          </div>
          <div className="logo">
            <Link to={"/"}>
              <img
                src="/images/hpk-trade-golden-logo.png"
                alt="logo"
                onClick={() => {
                  setmobileview(false);
                  setScrollToTop(true);
                }}
                style={{ width: "70px",padding:"8px" }}
              />
            </Link>
          </div>
          <Link to={"https://my.hpktrade.com/en/login"} className="commanbtn d-flex justify-contect-center align-items-center" target="_blank">
            <i class="fa-solid fa-user text-white"></i>
          </Link>
        </div>
        <div className="logorow">
          <div className="logo">
            <Link to={"/"}>
              <img src="" alt="logo" />
            </Link>
          </div>
          <div className="login">
            <Link
              to={process.env.REACT_APP_REGISTER_LINK}
              className="commanbtn"
              target="_blank"
            >
              {" "}
              Open Live Account
            </Link>
            <Link
              to={process.env.LOGIN_LINK}
              className="commanbtn"
              target="_blank"
            >
              Open Demo Account
            </Link>
          </div>
        </div>

        <ul className="header-list">
          <li className="listitemlogo">
            <Link to={"/"} className="" onClick={() => setScrollToTop(true)}>
              <img
                src="/images/hpk-trade-golden-logo.png"
                alt="logo"
                style={{ width: "74px" }}
              />
            </Link>
          </li>
          <li className="listitem" onMouseOver={showmenu1}>
            <h4 className="pt-2"> About</h4>
            {menu1 && (
              <ul className="submenu" onMouseLeave={hidemenu1}>
                <Link
                  to={"/aboutus"}
                  onClick={() => {
                    setmenu1(false);
                    setScrollToTop(true);
                  }}
                >
                  <li className=""> About Us</li>
                </Link>
                <Link
                  to={"/faq"}
                  onClick={() => {
                    setmenu1(false);
                    setScrollToTop(true);
                  }}
                >
                  <li> FAQ</li>
                </Link>
              </ul>
            )}
          </li>
          <li className="listitem" onMouseOver={showmenu2}>
            <h4 className="pt-2">Products</h4>
            {menu2 && (
              <ul className="submenu" onMouseLeave={hidemenu2}>
                <Link
                  to={"/forex"}
                  onClick={() => {
                    setmenu2(false);
                    setScrollToTop(true);
                  }}
                >
                  <li> Forex</li>
                </Link>
                <Link
                  to={"/metals"}
                  onClick={() => {
                    setmenu2(false);
                    setScrollToTop(true);
                  }}
                >
                  <li> Metals</li>
                </Link>
                <Link
                  to={"/Cryptocurrency"}
                  onClick={() => {
                    setmenu2(false);
                    setScrollToTop(true);
                  }}
                >
                  <li> Cryptocurrency</li>
                </Link>
                <Link
                  to={"/indices"}
                  onClick={() => {
                    setmenu2(false);
                    setScrollToTop(true);
                  }}
                >
                  <li> Indices</li>
                </Link>
              </ul>
            )}
          </li>
          <li className="listitem" onMouseOver={showmenu3}>
            <h4 className="pt-2">Platform</h4>
            {menu3 && (
              <ul className="submenu" onMouseLeave={hidemenu3}>
                <Link
                  to={"/mt5"}
                  onClick={() => {
                    setmenu3(false);
                    setScrollToTop(true);
                  }}
                >
                  <li> Meta Trader 5</li>
                </Link>
              </ul>
            )}
          </li>
          <li className="listitem" onMouseOver={showmenu4}>
            <h4 className="pt-2">Accounts</h4>
            {menu4 && (
              <ul className="submenu" onMouseLeave={hidemenu4}>
                <Link
                  to={"/accounts"}
                  onClick={() => {
                    setmenu4(false);
                    setScrollToTop(true);
                  }}
                >
                  <li>Standard</li>
                </Link>
                <Link
                  to={"/accounts"}
                  onClick={() => {
                    setmenu4(false);
                    setScrollToTop(true);
                  }}
                >
                  <li>Pro</li>
                </Link>
                <Link
                  to={"/accounts"}
                  onClick={() => {
                    setmenu4(false);
                    setScrollToTop(true);
                  }}
                >
                  <li>Ecn</li>
                </Link>
              </ul>
            )}
          </li>
          <li className="listitem" onMouseOver={showmenu5}>
            <h4 className="pt-2">Tools</h4>
            {menu5 && (
              <ul className="submenu" onMouseLeave={hidemenu5}>
                <Link
                  to={"/EconomicCalendar"}
                  onClick={() => {
                    setmenu5(false);
                    setScrollToTop(true);
                  }}
                >
                  <li>Economic Calendar</li>
                </Link>
                <Link
                  to={"/livechart"}
                  onClick={() => {
                    setmenu5(false);
                    setScrollToTop(true);
                  }}
                >
                  <li>Live Chart</li>
                </Link>
                <Link
                  to={"/liveprice"}
                  onClick={() => {
                    setmenu5(false);
                    setScrollToTop(true);
                  }}
                >
                  <li>Live Price</li>
                </Link>
                <Link
                  to={"/education"}
                  onClick={() => {
                    setmenu5(false);
                    setScrollToTop(true);
                  }}
                >
                  <li>Education</li>
                </Link>
              </ul>
            )}
          </li>
          <li
            className="listitem"
            onMouseOver={showmenu6}
            onMouseLeave={hidemenu6}
          >
            <Link
              to={"/contactus"}
              style={{ textDecoration: "none" }}
              onMouseOver={showmenu6}
              onMouseLeave={hidemenu6}
            >
              <h4 className="pt-2" style={{ color: "#fff" }}>
                Contact Us
              </h4>
            </Link>
          </li>
          {/* <li className='listitem' onMouseOver={showmenu6}><h4 className='pt-2'>Partner</h4>
                {menu6 && <ul className="submenu" onMouseLeave={hidemenu6}>
                   <Link to={'/partner'} onClick={()=>{setmenu6(false); setScrollToTop(true);}}>
                    <li> Introducing Brokers</li>
                   </Link>
                  
                    
                </ul>}
                </li> */}
          <li className="listitem">
            <Link
              to={process.env.REACT_APP_REGISTER_LINK}
              className="commanbtn m-0"
              target="_blank"
            >
              {" "}
              Open Live Account
            </Link>
          </li>
          <li className="listitem">
            <Link
              to={process.env.REACT_APP_REGISTER_LINK}
              className="commanbtn m-0"
              target="_blank"
            >
              {" "}
              Open Demo Account
            </Link>
          </li>
          <li className="listitem">
            <Link
              to={process.env.REACT_APP_LOGIN_LINK}
              className="commanbtn m-0"
              target="_blank"
            >
              Sign In
            </Link>
          </li>
        </ul>

        {/* //mobile  */}
        {mobileview && (
          <div className="mobilesidebar">
            <div className="closemenu" onClick={() => setmobileview(false)}>
              <i class="fa-solid fa-xmark" style={{ color: "#ffff" }}></i>
            </div>
            <ul className="header-list2">
              <li className="listitem2" onClick={show1}>
                <h4>
                  About <i class="fa-solid fa-caret-down " onClick={show1}></i>
                </h4>

                {menu1 && (
                  <ul className="submenu2">
                    <Link
                      to={"/aboutus"}
                      onClick={() => {
                        setmobileview(false);
                        setScrollToTop(true);
                      }}
                    >
                      <li>
                        {" "}
                        About {""}
                        {process.env.REACT_APP_APPNAME}
                      </li>
                    </Link>
                    <Link
                      to={"/faq"}
                      onClick={() => {
                        setmobileview(false);
                        setScrollToTop(true);
                      }}
                    >
                      <li> FAQ</li>
                    </Link>
                  </ul>
                )}
              </li>
              <li className="listitem2" onClick={show2}>
                <h4>
                  Products{" "}
                  <i class="fa-solid fa-caret-down" onClick={show2}></i>
                </h4>
                {menu2 && (
                  <ul className="submenu2" onMouseLeave={hidemenu2}>
                    <Link
                      to={"/forex"}
                      onClick={() => {
                        setmobileview(false);
                        setScrollToTop(true);
                      }}
                    >
                      <li> Forex</li>
                    </Link>
                    <Link
                      to={"/metals"}
                      onClick={() => {
                        setmobileview(false);
                        setScrollToTop(true);
                      }}
                    >
                      <li> Metals</li>
                    </Link>
                    <Link
                      to={"/Cryptocurrency"}
                      onClick={() => {
                        setmobileview(false);
                        setScrollToTop(true);
                      }}
                    >
                      <li> Cryptocurrency</li>
                    </Link>
                    <Link
                      to={"/indices"}
                      onClick={() => {
                        setmobileview(false);
                        setScrollToTop(true);
                      }}
                    >
                      <li> Indices</li>
                    </Link>
                  </ul>
                )}
              </li>
              <li className="listitem2" onClick={show3}>
                <h4>
                  Platform{" "}
                  <i class="fa-solid fa-caret-down" onClick={show3}></i>
                </h4>
                {menu3 && (
                  <ul className="submenu2" onMouseLeave={hidemenu3}>
                    <Link
                      to={"/mt5"}
                      onClick={() => {
                        setmobileview(false);
                        setScrollToTop(true);
                      }}
                    >
                      <li> Meta Trader 5</li>
                    </Link>
                  </ul>
                )}
              </li>
              <li className="listitem2" onClick={show4}>
                <h4>
                  Accounts{" "}
                  <i class="fa-solid fa-caret-down" onClick={show4}></i>
                </h4>
                {menu4 && (
                  <ul className="submenu2" onMouseLeave={hidemenu4}>
                    <Link
                      to={"/accounts"}
                      onClick={() => {
                        setmobileview(false);
                        setScrollToTop(true);
                      }}
                    >
                      <li>Standard</li>
                    </Link>
                    <Link
                      to={"/accounts"}
                      onClick={() => {
                        setmobileview(false);
                        setScrollToTop(true);
                      }}
                    >
                      <li>Pro</li>
                    </Link>
                    <Link
                      to={"/accounts"}
                      onClick={() => {
                        setmobileview(false);
                        setScrollToTop(true);
                      }}
                    >
                      <li>Ecn</li>
                    </Link>
                  </ul>
                )}
              </li>
              <li className="listitem2" onClick={show5}>
                <h4>
                  Tools <i class="fa-solid fa-caret-down" onClick={show5}></i>
                </h4>
                {menu5 && (
                  <ul className="submenu2" onMouseLeave={hidemenu5}>
                    <Link
                      to={"/EconomicCalendar"}
                      onClick={() => {
                        setmobileview(false);
                        setScrollToTop(true);
                      }}
                    >
                      <li>Economic Calendar</li>
                    </Link>
                    <Link
                      to={"/livechart"}
                      onClick={() => {
                        setmobileview(false);
                        setScrollToTop(true);
                      }}
                    >
                      <li>Live Chart</li>
                    </Link>
                    <Link
                      to={"/liveprice"}
                      onClick={() => {
                        setmobileview(false);
                        setScrollToTop(true);
                      }}
                    >
                      <li>Live Price</li>
                    </Link>
                    <Link
                      to={"/education"}
                      onClick={() => {
                        setmobileview(false);
                        setScrollToTop(true);
                      }}
                    >
                      <li>Education</li>
                    </Link>
                  </ul>
                )}
              </li>
              {/* <li className='listitem2' onClick={show6}><h4>Partner <i class="fa-solid fa-caret-down" onClick={show6} ></i></h4>
                {menu6 && <ul className="submenu2" onMouseLeave={hidemenu6}>
                   <Link to={'/partner'} onClick={()=>{ setmobileview(false); setScrollToTop(true);}}>
                    <li> INTRODUCING BROKERS</li>
                   </Link>
                  
                    
                </ul>}
                </li> */}
              <li className="listitem2">
                <Link
                  to={"/contactus"}
                  onClick={() => {
                    setmobileview(false);
                    setScrollToTop(true);
                  }}
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <h4>Contact Us</h4>
                </Link>
              </li>
              <li className="listitem2">
                <Link
                  to={process.env.REACT_APP_REGISTER_LINK}
                  className="commanbtn"
                  target="_blank"
                >
                  {" "}
                  Open Live Account
                </Link>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </div>
  );
};

export default Header;
