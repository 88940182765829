import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const Footer = () => {
  const [menu1, setmenu1] = useState(false);
  const [menu2, setmenu2] = useState(false);
  const [menu3, setmenu3] = useState(false);
  const [menu4, setmenu4] = useState(false);
  const [menu5, setmenu5] = useState(false);
  const [scrollToTop, setScrollToTop] = useState(false);

  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setScrollToTop(false);
    }
  }, [scrollToTop]);
  function showmenu1() {
    setmenu1(!menu1);
  }
  function showmenu2() {
    setmenu2(!menu2);
  }
  function showmenu3() {
    setmenu3(!menu3);
  }
  function showmenu4() {
    setmenu4(!menu4);
  }
  function showmenu5() {
    setmenu5(!menu5);
  }
  return (
    <div>
      <section className="footersection">
        <Container>
          <Row className="footrrow">
            <Col lg={2}>
              <h5 className="footerttitle">LINKS</h5>

              <ul className="footerul">
                <li>
                  {" "}
                  <Link to={process.env.REACT_APP_LOGIN_LINK} target="_blank">
                    Log In
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link
                    to={process.env.REACT_APP_REGISTER_LINK}
                    target="_blank"
                  >
                    {" "}
                    Live Account
                  </Link>
                </li>
                <li>
                  <Link to={"/aboutus"} onClick={() => setScrollToTop(true)}>
                    About Us
                  </Link>{" "}
                </li>
                <li>
                  <Link to={"/faq"} onClick={() => setScrollToTop(true)}>
                    FAQ
                  </Link>{" "}
                </li>
              </ul>
            </Col>
            <Col lg={2}>
              <h5 className="footerttitle">ACCOUNTS</h5>
              <ul className="footerul">
                <li>
                  <Link to={"/accounts"} onClick={() => setScrollToTop(true)}>
                    Pro Account
                  </Link>{" "}
                </li>
                <li>
                  <Link to={"/accounts"} onClick={() => setScrollToTop(true)}>
                    ECN Account
                  </Link>{" "}
                </li>
                <li>
                  <Link to={"/accounts"} onClick={() => setScrollToTop(true)}>
                    Standard Account
                  </Link>{" "}
                </li>
              </ul>
            </Col>
            <Col>
              <h5 className="footerttitle">TRADING PLATFORM</h5>
              <ul className="footerul">
                <li>
                  <Link to={"/MT5"} onClick={() => setScrollToTop(true)}>
                    MT5
                  </Link>{" "}
                </li>
              </ul>
            </Col>
            <Col lg={2}>
              <h5 className="footerttitle">MARKTES</h5>
              <ul className="footerul">
                <li>
                  <Link to={"/Forex"} onClick={() => setScrollToTop(true)}>
                    Forex
                  </Link>{" "}
                </li>
                <li>
                  <Link to={"/Metals"} onClick={() => setScrollToTop(true)}>
                    Metals
                  </Link>{" "}
                </li>
                <li>
                  <Link to={"/Indices"} onClick={() => setScrollToTop(true)}>
                    Indices
                  </Link>{" "}
                </li>
                <li>
                  <Link
                    to={"/Cryptocurrency"}
                    onClick={() => setScrollToTop(true)}
                  >
                    Cryptocurrency
                  </Link>{" "}
                </li>
              </ul>
            </Col>
            <Col lg={4}>
              <h5 className="footerttitle">Contact Info.</h5>
              <ul className="footerul">
                <li>
                  <i class="fa-solid fa-location-dot"></i>
                  <span>
                    <b className="address-color">Physical Address:</b>
                    {process.env.REACT_APP_PHYSICAL_ADDRESS}
                  </span>{" "}
                </li>
                <li>
                  <i class="fa-solid fa-location-dot"></i>
                  <span>
                    <b className="address-color">Registered Address:</b>
                    {process.env.REACT_APP_REGISTER_ADDRESS}
                  </span>{" "}
                </li>
                <li>
                  <i class="fa-solid fa-phone"></i>{" "}
                  <span>{process.env.REACT_APP_MOBILENO}</span>
                </li>
                <li>
                  <i class="fa-solid fa-envelope"></i>
                  <span>{process.env.REACT_APP_EMAIL}</span>
                </li>
              </ul>
            </Col>
          </Row>
          {/* formobile view */}
          <Container className="forfootermobile">
            <Row>
              <Col>
                <h5 className="footerttitle" onClick={showmenu1}>
                  LINKS{" "}
                  <i class="fa-solid fa-caret-down" onClick={showmenu1}></i>
                </h5>

                {menu1 && (
                  <ul className="footerul">
                    <li>
                      {" "}
                      <Link
                        to={process.env.REACT_APP_LOGIN_LINK}
                        target="_blank"
                      >
                        Log In
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link
                        to={process.env.REACT_APP_REGISTER_LINK}
                        target="_blank"
                      >
                        {" "}
                        Live Account
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/aboutus"}
                        onClick={() => setScrollToTop(true)}
                      >
                        About Us
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to={"/faq"} onClick={() => setScrollToTop(true)}>
                        FAQ
                      </Link>{" "}
                    </li>
                  </ul>
                )}
              </Col>
            </Row>
            <Row>
              <div>
                <h5 className="footerttitle" onClick={showmenu2}>
                  ACCOUNTS{" "}
                  <i class="fa-solid fa-caret-down" onClick={showmenu2}></i>
                </h5>
              </div>

              {menu2 && (
                <ul className="footerul" style={{ marginLeft: "30px" }}>
                  <li>
                    <Link to={"/accounts"} onClick={() => setScrollToTop(true)}>
                      Pro Account
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to={"/accounts"} onClick={() => setScrollToTop(true)}>
                      ECN Account
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to={"/accounts"} onClick={() => setScrollToTop(true)}>
                      Standard Account
                    </Link>{" "}
                  </li>
                </ul>
              )}
            </Row>
            <Row>
              <div>
                <h5 className="footerttitle" onClick={showmenu3}>
                  MARKTES{" "}
                  <i class="fa-solid fa-caret-down" onClick={showmenu3}></i>
                </h5>
              </div>
              {menu3 && (
                <ul className="footerul" style={{ marginLeft: "30px" }}>
                  <li>
                    <Link to={"/Forex"} onClick={() => setScrollToTop(true)}>
                      Forex
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to={"/Metals"} onClick={() => setScrollToTop(true)}>
                      Metals
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to={"/Indices"} onClick={() => setScrollToTop(true)}>
                      Indices
                    </Link>{" "}
                  </li>
                  <li>
                    <Link
                      to={"/Cryptocurrency"}
                      onClick={() => setScrollToTop(true)}
                    >
                      Cryptocurrency
                    </Link>{" "}
                  </li>
                </ul>
              )}
            </Row>
            <Row>
              <div>
                <h5 className="footerttitle" onClick={showmenu4}>
                  TRADING PLATFORM{" "}
                  <i class="fa-solid fa-caret-down" onClick={showmenu4}></i>
                </h5>
              </div>
              {menu4 && (
                <ul className="footerul" style={{ marginLeft: "30px" }}>
                  <li>
                    <Link to={"/MT5"} onClick={() => setScrollToTop(true)}>
                      MT5
                    </Link>{" "}
                  </li>
                </ul>
              )}
            </Row>
            <Row>
              <div>
                <h5 className="footerttitle" onClick={showmenu5}>
                  Contact{" "}
                  <i class="fa-solid fa-caret-down" onClick={showmenu5}></i>
                </h5>
              </div>
              {menu5 && (
                <ul className="footerul" style={{ marginLeft: "30px" }}>
                  <li>
                    <i class="fa-solid fa-phone"></i>{" "}
                    <span>{process.env.REACT_APP_MOBILENO}</span>
                  </li>
                  <li>
                    <i class="fa-solid fa-envelope"></i>
                    <span>{process.env.REACT_APP_EMAIL}</span>
                  </li>
                  <li>
                    <i class="fa-solid fa-location-dot"></i>
                    <span>
                      <b className="address-color">Physical Address:{" "}</b>
                      {" "} {process.env.REACT_APP_PHYSICAL_ADDRESS}
                    </span>{" "}
                  </li>
                  <li>
                    <i class="fa-solid fa-location-dot"></i>
                    <span>
                      <b className="address-color">Registered Address:</b>
                      {process.env.REACT_APP_REGISTER_ADDRESS}
                    </span>{" "}
                  </li>
                  <li>
                    <i class="fa-solid fa-clock"></i>
                    <span>Timings: 24/7</span>{" "}
                  </li>
                </ul>
              )}
            </Row>
          </Container>
          <Row>
            <hr style={{ color: "#000", margin: "10px 0" }} />
          </Row>
          <Row>
            <div className="text-center">
              <ul className="socialmediaul">
                <li>
                  <Link to={"https://www.facebook.com/people/HPK-Trade/61565000793182/"} target="_blank">
                    {" "}
                    <i class="fa-brands fa-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link to={"https://www.instagram.com/hpktrade_ltd/"} target="_blank">
                    {" "}
                    <i class="fa-brands fa-instagram"></i>
                  </Link>
                </li>
                <li>
                  <Link to={"/"} target="_blank">
                    <i class="fa-brands fa-twitter"></i>{" "}
                  </Link>
                </li>
                <li>
                  <Link to={"https://www.youtube.com/@hpktrade"} target="_blank">
                    {" "}
                    <i class="fa-brands fa-youtube"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </Row>
          <Row>
            <hr style={{ color: "#000", margin: "5px 0" }} />
          </Row>
          <Row>
            <p className="footertext">
              <strong> Risk Warning:</strong> An investment in derivatives may
              mean investors may lose an amount even greater than their original
              investment. Anyone wishing to invest in any of the products
              mentioned in www.hpktrade.com should seek their own financial or
              professional advice. Trading of securities, forex, stock market,
              commodities, options and futures may not be suitable for everyone
              and involves the risk of losing part or all of your money. Trading
              in the financial markets has large potential rewards, but also
              large potential risk. You must be aware of the risks and be
              willing to accept them in order to invest in the markets. Don't
              invest and trade with money which you can't afford to lose. Forex
              Trading are not allowed in some countries, before investing your
              money, make sure whether your country is allowing this or not.
            </p>
            <p className="footertext">
              You are strongly advised to obtain independent financial, legal
              and tax advice before proceeding with any currency or spot metals
              trade. Nothing in this site should be read or construed as
              constituting advice on the part of HPK Trade or any of its
              affiliates, directors, officers or employees.
            </p>
            <p className="footertext" >
            <strong>Regional Restrictions:</strong> HPK Trade does not provide services for citizens/residents of the
              United States, Cuba, Iraq, Myanmar, North Korea, Sudan. The
              services of HPk Trade are not intended for distribution to, or use
              by, any person in any country or jurisdiction where such
              distribution or use would be contrary to local law or regulation.
            </p>
          </Row>

          <Row>
            <hr style={{ color: "#000", margin: "5px 0" }} />
          </Row>
          <Row>
            <ul className="footerul copyright">
              <li>
                <i class="fa-regular fa-copyright"></i>
                <span>HPK Trade 2024 | All Rights Reserved</span>
              </li>
            </ul>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Footer;
