import React from 'react'
import { Container, Row ,Col} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import { useEffect } from 'react'
import 'aos/dist/aos.css';


const Dwonloadnow = () => {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <section className='dwonloadnowsection'>
        <Container>
            <Row>
                <Col lg={6} md={6} sm={12}  >
                    <div className="dwonloadtextside">
                        <div className="titledwonload">
                            <h2>The Future Of Trading Is Here</h2>
                        </div>
                      
                            <p className="myp">
                        With {process.env.REACT_APP_APPNAME}you can trade forex almost like it's a regular stock! It's as easy as depositing and withdrawing funds, with no minimums or other restrictions.
                            </p>

                <div className='dwomalodbtn'>
                    <Link to={'/'} target='_blank'>
                    <img src="/images/1684843044_1673442375_play-store-icon (2).png" alt="" />
                    </Link>
                    <Link to={'/'} target='_blank'>
                    <img src="/images/1684846782_1674471676_ios_app-store-icon.png" alt="" />
                    </Link>
                </div>              
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} className='dwomloadcol'  >
                    <div className="text-center dwonloadimg">
                    <img src="./images/ibkr-mobile-hero.png" alt="" width={'50%'} className='' />

                        
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default Dwonloadnow