import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const TradeNow = () => {
  return (
    <section className='tradenowsection'>
        <Container>
            <Row>
                <div className="text-center tradetext">
                    <h2>TRADE WITH US 
AND MAKE A
DIFFERENCE
</h2>
                </div>
            </Row>
            <Row>
                <div className='tradenow'>
                    <div className='tradebanner'>

                <Link to={process.env.REACT_APP_REGISTER_LINK} className='commanbtn2' target='_blank'> Open Live Account</Link>
                    </div>
                    <div className='tradebanner'>

                    <Link to={process.env.REACT_APP_DEMO_LINK} className='commanbtn2' target='_blank'>Open Demo Account</Link>
                    </div>

                </div>
            </Row>
        </Container>
    </section>
  )
}

export default TradeNow