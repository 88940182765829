import React from 'react'
import Pagebanner from '../Pagebanner'
import Faqs from '../Faqs';
import {  Container, Row } from 'react-bootstrap';
const pagebanner={
    title:"FAQ",
    text:"FREQUENTLY ASKED QUESTION",
    name:"FAQ",
    pagelink:"faq"
  }
  const FAQs = [
    {
      title: "How long does it take to open an account?",
      body:"After verification, our backend office support staff will process your account immediately."
    },
    {
      title: "Is there a minimum funding requirement for opening an account?",
      body:` The minimum funding requirement is 100 USD. ${process.env.REACT_APP_APPNAME} does not charge for account opening and neither does it have funding limits`
    },
    {
      title: 'What leverage options are available? Can customers change leverage at will? If yes, how?',
      body:`We offer two leverage options 1:100 1:200 You choose chose leverage options while registering for an account. If you wish to change your leverage option it can be done by contacting our back customer service via email, ${process.env.REACT_APP_EMAIL}`
    },
    {
      title: "Do you have trial accounts or simulation accounts?",
      body:"Our simulated account is known as a Demo Account. In order to create a Demo Account you can install the MT5 software directly and apply the simulation account to the software"
    },
    {
      title: "What currency do you use for trading?",
      body:`At${process.env.REACT_APP_APPNAME} , clients can trade U.S. dollars. However, those who wish to trade in AED in UAE or in NGN in Nigeria can do so. For that ${process.env.REACT_APP_APPNAME} charges, a fair and transparent exchange rate (without any extra charges) as the account settlement currency is USD.`
    },
    {
      title: "How much do I need to invest in a transaction?",
      body:" As soon as you complete your account, your mailbox will receive an email with an account number and password. After you fund your account you can immediately begin transactions."
    },
    {
      title: "What should I do if I forget my account password?",
      body:"You can trade any amount with minimum of $100 to unlimited, depending on your comfort and risk tolerance."
    },
    {
      title: "Which website do you need to log in to to pay the online deposit?",
      body:" Customers can log on to our center, at Login buttun . For smoother services, we recommend that clients login using the i.e. (internet explorer) browser."
    },
    
   
    
  ];
const FAQ = () => {
  return (
    <div>
        <Pagebanner title={pagebanner.title} text={pagebanner.text}  pagename={pagebanner.name}  pagelink={pagebanner.pagelink}/>
        <section>

<Container>
    <Row>
        <div className="text-center">
            <h2 className='sectiontitle new-color'>Frequently Asked Question</h2>
        </div>
    </Row>
    <Row>
        
    {FAQs.map((item) => (

<Faqs  title={item.title} body={item.body}/>

))}
    </Row>
</Container>

</section>
        

    </div>
  )
}

export default FAQ