import React from 'react'
import { MarketData } from "react-ts-tradingview-widgets";
import Pagebanner from '../Pagebanner'
import { Container, Row ,Col} from 'react-bootstrap'
import TradeNow from '../TradeNow';
import Whychose from '../Whychose';
import StepProcess from '../StepProcess';
import OurProducts from '../OurProducts';
import Whyus from '../Whyus';

const pagebanner={
    title:"metals",
 
    name:"Metals",
    pagelink:"metals"
  }
const Metals = () => {
  return (
    <div>
        <Pagebanner title={pagebanner.title} text={pagebanner.text}  pagename={pagebanner.name}  pagelink={pagebanner.pagelink}/>
        <section>
        <Container>
            <Row className='titlerow2'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle  new-color">WHAT IS metals trading</h2>
            </div>

        </Row>
            <Row>
                <div className="text-center">
                 <p>Metals trading refers to the buying and selling of various metals in the financial markets. Metals are valuable commodities that have industrial, commercial, and investment uses. Traders engage in metals trading for various reasons, including speculation, hedging against price fluctuations, and portfolio diversification.</p>
                 <p>
                 {process.env.REACT_APP_APPNAME} Group offers metals major, cross and exotic pairs to trade in the metals markets with deep liquidity. Benefit from the best trading tools and cutting-edge MetaTrader 5 trading platforms, available on all our accounts.
                 </p>
                </div>
            </Row>
            </Container>
        </section>
        <section>
          <OurProducts/>
        </section>
        <section>
          <Container>
          <Row className='titlerow2'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle  new-color">MOST POPULAR  PAIRS</h2>
            </div>

        </Row>
            <Row>
            <MarketData colorTheme="light" width="100%" height={450}></MarketData>
            </Row>
          </Container>
        </section>
        <section>
          <Whyus/>
        </section>
        <section>
  <TradeNow/>
    </section>
    <section>
        <Container>
        
        <Row>
            <StepProcess/>
        </Row>
        </Container>
    </section>
    </div>
  )
}

export default Metals